import { useEffect, useState } from "react";
import { TitleContext } from "./useTitle";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

interface TitleProviderProps {
  children: React.ReactChild;
}

const titleIcon = <KeyboardArrowRightIcon />;
const titlePrefix = "Ordering 4.0 Suite";

export default function TitleProvider(props: TitleProviderProps) {
  const [title, setTitle] = useState<string>(titlePrefix);

  useEffect(() => {
    document.title = "Rock5 | " + title;
  }, [title]);

  const setSubTitle = (newSubTitle: string) => {
    if (newSubTitle !== "") {
      setTitle(titlePrefix + " - " + newSubTitle);
    } else {
      setTitle(titlePrefix);
    }
  };

  return (
    <TitleContext.Provider value={{ title, setSubTitle }}>
      {props.children}
    </TitleContext.Provider>
  );
}
