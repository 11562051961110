import { Route, RouteObject, RouterProvider, Routes, useParams } from 'react-router';

import ApiInfo from '../pages/apiInfo/ApiInfo';
import { AuthProvider } from '../contexts/auth/AuthProvider';
import Customer from '../pages/customer/Customer';
import Customers from '../pages/management/Customers';
import CustomersProvider from '../contexts/customers/CustomersProvider';
import Hardware from '../pages/management/Hardware';
import { HardwareProvider } from '../contexts/HardwareContext';
import Location from '../pages/location/Location';
import Locations from '../pages/location/Locations';
import { Login } from '../pages/login/Login';
import MenuWrapper from '../menuWrapper/MenuWrapper';
import Products from '../pages/management/Products';
import Properties from '../pages/management/Properties';
import PropertiesProvider from '../contexts/properties/PropertiesProvider';
import PropertyDetails from '../pages/property/Property';
import { PropertyLoader } from '../loaders/PropertyLoader';
import RequireAuth from '../contexts/auth/RequireAuth';
import Settings from '../pages/settings/Settings';
import SettingsProvider from '../contexts/settings/SettingsProvider';
import Summary from '../pages/summary/Summary';
import { TenantProvider } from '../contexts/TenantContext';
import Tenants from '../pages/management/Tenants';
import TitleProvider from '../contexts/title/TitleProvider';
import Titled from '../contexts/title/Titled';
import Units from '../pages/management/Units';
import { UnitsProvider } from '../contexts/UnitsContext';
import { createBrowserRouter } from 'react-router-dom';

// Wrapper component for UnitsProvider
const UnitsProviderWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { propertyId } = useParams<{ propertyId: string }>();
  if (!propertyId) return null; // or handle the case when propertyId is undefined

  return <UnitsProvider propertyId={propertyId}>{children}</UnitsProvider>;
};

const HardwareProviderWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { propertyId } = useParams<{ propertyId: string }>();
  if (!propertyId) return null; // or handle the case when propertyId is undefined

  return <HardwareProvider propertyId={propertyId}>{children}</HardwareProvider>;
};

const TenantProviderWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { propertyId } = useParams<{ propertyId: string }>();
  if (!propertyId) return null; // or handle the case when propertyId is undefined

  return <TenantProvider propertyId={propertyId}>{children}</TenantProvider>;
};

const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <AuthProvider>
        <RequireAuth>
          <TitleProvider>
            <CustomersProvider>
              <PropertiesProvider>
                <SettingsProvider>
                  <MenuWrapper title='Ordering 4.0' />
                </SettingsProvider>
              </PropertiesProvider>
            </CustomersProvider>
          </TitleProvider>
        </RequireAuth>
      </AuthProvider>
    ),
    children: [
      {
        path: 'properties',
        element: (
          <AuthProvider>
            <RequireAuth>
              <Titled title='Liegenschaften'>
                <Properties />
              </Titled>
            </RequireAuth>
          </AuthProvider>
        ),
      },
      {
        path: 'property/:propertyId',
        element: (
          <AuthProvider>
            <RequireAuth>
              <PropertyDetails />
            </RequireAuth>
          </AuthProvider>
        ),
        loader: PropertyLoader,
        children: [
          {
            path: 'units',
            element: (
              <UnitsProviderWrapper>
                <Units />
              </UnitsProviderWrapper>
            ),
          },
          {
            path: 'hardware',
            element: (
              <HardwareProviderWrapper>
                <Hardware />
              </HardwareProviderWrapper>
            ),
          },
          {
            path: 'tenants',
            element: (
              <TenantProviderWrapper>
                <Tenants />
              </TenantProviderWrapper>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: (
      <AuthProvider>
        <Login />
      </AuthProvider>
    ),
  },
];

const router = createBrowserRouter(routes);

const ContentRouter = () => <RouterProvider router={router} />;

export default ContentRouter;

// export default function ContentRouter() {
//   return (
//     <Routes>
//       <Route
//         path='property/:propertyId/*'
//         element={
//           <Routes>
//             <Route path='/' loader={PropertyLoader} element={<PropertyDetails />} />
//             <Route path='units' element={<Units />} />
//             <Route path='hardware' element={<Hardware />} />
//             <Route path='tenants' element={<Tenants />} />
//           </Routes>
//         }
//       />
//       <Route
//         path='properties'
//         element={
//           <Titled title='Liegenschaften'>
//             <Properties />
//           </Titled>
//         }
//       />
//       <Route
//         path='summary'
//         element={
//           <Titled title='Übersicht'>
//             <Summary />
//           </Titled>
//         }
//       />
//       <Route
//         path='products'
//         element={
//           <Titled title='Produkte'>
//             <Products />
//           </Titled>
//         }
//       />
//       <Route
//         path='customers'
//         element={
//           <Titled title='Kunden'>
//             <Customers />
//           </Titled>
//         }
//       />
//       <Route
//         path='customers/:customerId/*'
//         element={
//           <Routes>
//             <Route path='/' element={<Customer />} />
//             <Route path='locations' element={<Locations />} />
//             <Route path='locations/:locationId' element={<Location />} />
//           </Routes>
//         }
//       />
//       <Route
//         path='apiinfo'
//         element={
//           <Titled title='Api Info'>
//             <ApiInfo />
//           </Titled>
//         }
//       />
//     </Routes>
//   );
// }
