import './menuWrapper.scss';

import {
  Apartment,
  ApartmentOutlined,
  ElectricMeter,
  GasMeter,
  Key,
  MeetingRoom,
} from '@mui/icons-material';
import { Link, Navigate, Outlet } from 'react-router-dom';
import Menu, { MenuItemProps } from './Menu';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import ApiIcon from '@mui/icons-material/Api';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CssBaseline from '@mui/material/CssBaseline';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import Toolbar from '@mui/material/Toolbar';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import useAuth from '../contexts/auth/useAuth';
import useCustomers from '../contexts/customers/useCustomers';
import useProperties from '../contexts/properties/useProperties';
import useTitle from '../contexts/title/useTitle';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  maxWidth: '100vw',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  backgroundColor: '#100c30',
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    backgroundColor: '#100c30',
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

interface MenuWrapperProps {
  title?: string;
}

export default function MenuWrapper(props: MenuWrapperProps) {
  const [isDark, setIsDark] = useState(false);

  const toggleIsDark = () => {
    setIsDark((current) => !current);
    console.log(isDark);
  };

  const theme = useTheme();
  const { properties } = useProperties();

  const [open, setOpen] = useState<boolean>(true);
  const [menuData, setMenuData] = useState<MenuItemProps[]>();

  const auth = useAuth();
  const { customers } = useCustomers();
  const { title } = useTitle();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logout = () => {
    auth.logout(() => '/login');
  };

  useEffect(() => {
    if (!customers) return;
    setMenuData([]);
    for (let customer of customers) {
      setMenuData((menuData) => [
        ...(menuData ?? []),
        {
          title: customer.name,
          link: 'customers/' + customer.id,

          children: customer.storageLocations?.map((location) => ({
            title: location.name,
            link: 'customers/' + customer.id + '/locations/' + location.id,
          })),
        },
      ]);
    }
  }, [customers]);

  return (
    <Box className='Box' sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar className='Navbar' open={open}>
        <Toolbar className={isDark ? 'Toolbar darkmode' : 'Toolbar'}>
          <div className='IconMenu'>
            <IconButton
              id='IconButton'
              className='iconButton'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>

            <Box
              className={isDark ? 'Logo darkmode' : 'Logo'}
              style={{ display: !open ? 'block' : 'none' }}
              sx={{
                background: 'url(/logo.svg)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                height: '25px',
                width: '175px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            ></Box>
          </div>
          <Box sx={{ fontWeight: 'bold' }}>UVI - Portal</Box>
          <Box sx={{ flex: '1' }}></Box>
          <div className='items'>
            <div className='Mail'>{auth.user.email}</div>
            <Button
              className='logout'
              onClick={() => {
                logout();
              }}
            >
              <LogoutIcon className='icons' />
              <span className='text'>Logout</span>
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        className='drawer'
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            overflow: 'hidden',
          },
        }}
        variant='persistent'
        anchor='left'
        open={open}
      >
        <DrawerHeader className='drawerHeader'>
          <Box
            className={isDark ? 'Logo darkmode' : 'Logo'}
            sx={{
              background: 'url(/logo.svg)',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              height: '25px',
              width: '175px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
            }}
          ></Box>
          <IconButton id='IconButton' className='iconButton' onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>

        <div className={isDark ? 'sidebar darkmode' : 'sidebar'}>
          <hr />

          {/* <p className='title'>START</p>
          <Box sx={{ minWidth: 250 }}>
            <SimpleTreeView>
              <TreeItem itemId='grid' label='Data Grid'>
                <TreeItem itemId='grid-community' label='@mui/x-data-grid' />
                <TreeItem itemId='grid-pro' label='@mui/x-data-grid-pro' />
                <TreeItem itemId='grid-premium' label='@mui/x-data-grid-premium' />
              </TreeItem>
            </SimpleTreeView>
          </Box> */}
          <Box sx={{ padding: '0.5rem' }}>
            <p className='title'>
              <Link to='/properties' style={{ textDecoration: 'none', fontWeight: 'bold' }}>
                LIEGENSCHAFTEN
              </Link>
            </p>
          </Box>

          <Box>
            <SimpleTreeView
              sx={{ color: 'crimson' }}
              disableSelection
              slots={{
                expandIcon: Apartment,
                collapseIcon: ApartmentOutlined,
              }}
            >
              {properties?.map((property, index) => {
                return (
                  <TreeItem
                    className='treeItemParent'
                    itemId={'property-' + property.id}
                    sx={{ paddingBottom: '1rem', paddingTop: '1rem' }}
                    label={
                      <Link
                        to={'/property/' + property.id}
                        style={{
                          textDecoration: 'none',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {property.name}
                      </Link>
                    }
                  >
                    <TreeItem
                      slots={{
                        icon: MeetingRoom,
                      }}
                      itemId={'property-units-' + property.id}
                      label={
                        <Link
                          to={'/property/' + property.id + '/units'}
                          style={{
                            textDecoration: 'none',
                          }}
                        >
                          Nutzeinheiten
                        </Link>
                      }
                    />
                    <TreeItem
                      itemId={'property-hardware-' + property.id}
                      slots={{
                        icon: ElectricMeter,
                      }}
                      label={
                        <Link
                          to={'/property/' + property.id + '/hardware'}
                          style={{ textDecoration: 'none' }}
                        >
                          Gerätebestand
                        </Link>
                      }
                    />
                    <TreeItem
                      slots={{
                        icon: Key,
                      }}
                      itemId={'property-tenants-' + property.id}
                      label={
                        <Link
                          to={'/property/' + property.id + '/tenants'}
                          style={{ textDecoration: 'none' }}
                        >
                          Bewohner
                        </Link>
                      }
                    />
                  </TreeItem>
                );
              })}
            </SimpleTreeView>
          </Box>
        </div>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
}
